html {
  background: #222;
}

body,
#root {
  min-height: 100vh;
}

a {
  color: #7a89d6;
  text-decoration: none;
}

a:visited {
  color: #70619a;
  text-decoration: none;
}
