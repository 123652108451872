.player {
  width: 100%;
  height: 100%;
  min-height: 640px;
  box-sizing: border-box;
}

.player-fullscreen {
  width: 100%;
  height: 100vh;
}
